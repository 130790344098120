<template>
  <div class="reports_section_summary" :class="isFullScreenMode?'reports_section_full_screen':''">
    <div style="width: 100%">
      <b-input v-model="query_filter" placeholder="Type to search"></b-input>
    </div>
    <b-table bordered small selectable select-mode="single" :fields="columns" :items="users" @row-clicked="rowClicked"
             :filter="query_filter" :filter-included-fields="['username', 'children']">
      <template #thead-top="data">
        <b-tr>
          <b-th colspan="" class="text-center"><span>User</span></b-th>
          <b-th colspan="2" class="text-center reports_pointer"
                v-tippy="{ placement : 'top',  arrow: true }"
                content="Graphic credits used. (Default option)"
                :class="$store.state.reportSoftwareDashboardStore.column_selected === 'price'?'reports_columns_active':''"
                @click="$store.commit('reportSoftwareDashboardStore/setColumSelected','price')">
            <span>Credits used</span></b-th>
          <b-th colspan="2" class="text-center reports_pointer"
                v-tippy="{ placement : 'top',  arrow: true }"
                content="Graphic tunes sold used"
                :class="$store.state.reportSoftwareDashboardStore.column_selected === 'total_rows'?'reports_columns_active':''"
                @click="$store.commit('reportSoftwareDashboardStore/setColumSelected', 'total_rows')">
            <span>Tunes sold</span></b-th>
          <b-th colspan="2" class="text-center reports_pointer"
                v-tippy="{ placement : 'top',  arrow: true }"
                content="Graphic support tickets"
                :class="$store.state.reportSoftwareDashboardStore.column_selected === 'support'?'reports_columns_active':''"
                @click="$store.commit('reportSoftwareDashboardStore/setColumSelected', 'support')">
            <span>Support tickets</span></b-th>
        </b-tr>
      </template>
      <template #cell(username)="{item}">
        <div class="d-flex">
          <div class="">
            <b-form-checkbox v-model="selected" :value="item.id"></b-form-checkbox>
          </div>
          <div class="">
            <span :style="{background: item.colors.backgroundColor}" class="pb-0 mb-0 reports_dot"></span>
            <span class="pb-0 mb-0 text-left" :class="item.children.length?'font-weight-bold':'font-weight-bolder'"
                  v-tippy="{ placement : 'top',  arrow: true }"
                  :content="item.username +'<br>'+ item.email + '<br>' + item.alpha3_code">
              {{ (item.username) ? item.username.toUpperCase().substring(0, 15) : '' }}</span>
          </div>
        </div>
      </template>
      <template #cell(current.price)="{item}">
        <span class="font-weight-bolder"
              :class="item.current.price < 0 ? 'text-danger': ''">{{ item.current.price|euro }}</span>
      </template>
      <template #cell(current.total_rows)="{item}">
        <span class="font-weight-bolder"
              :class="item.current.total_rows < 0 ? 'text-danger': ''">{{ item.current.total_rows }}</span>
      </template>
      <template #cell(current.support)="{item}">
        <span class="font-weight-bolder"
              :class="item.current.support < 0 ? 'text-danger': ''">{{ item.current.support }}</span>
      </template>

      <template #cell(previous.price)="{item}">
        <rows-balances :variation="item.variation.credits" :amount="item.previous.price" :filter="'euro'"/>
      </template>
      <template #cell(previous.total_rows)="{item}">
        <rows-balances :variation="item.variation.tune" :amount="item.previous.total_rows"/>
      </template>
      <template #cell(previous.support)="{item}">
        <rows-balances :variation="item.variation.support" :amount="item.previous.support"/>
      </template>

      <template #row-details="{item}">
        <detail-users :array_sub_users="item.children"
                      style="border: 1px solid black; padding-bottom: 0; margin-bottom: 0">
          <template #default="item">
            <b-form-checkbox v-model="selected" :value="item.id">
              <span
                class="pb-0 mb-0 font-weight-bolder">
                {{ (item.username) ? item.username.toUpperCase().substring(0, 20) : '' }}
              </span>
            </b-form-checkbox>
          </template>
        </detail-users>
      </template>
    </b-table>
  </div>
</template>
<script>
import {mapMutations, mapState} from "vuex";
import rowsBalances from "../_rowsVariation"
import detailUsers from "./_detailUser"
import fullscreen from "@/utils/fullscreen";

export default {
  name: "dashboard_summary",
  components: {
    rowsBalances,
    detailUsers
  },
  mixins: [fullscreen],
  data: () => ({
    columns: [
      {key: 'username', label: '', sortable: true},
      {
        key: 'current.price',
        label: 'current',
        tdClass: 'text-right reports_pointer',
        thClass: 'text-center',
        sortable: true
      },
      {
        key: 'previous.price',
        label: 'previous',
        tdClass: 'text-right reports_pointer',
        thClass: 'text-center',
        sortable: true
      },
      {
        key: 'current.total_rows',
        label: 'current',
        tdClass: 'text-right reports_pointer',
        thClass: 'text-center',
        sortable: true
      },
      {
        key: 'previous.total_rows',
        label: 'previous',
        tdClass: 'text-right reports_pointer',
        thClass: 'text-center',
        sortable: true
      },
      {
        key: 'current.support',
        label: 'current',
        tdClass: 'text-right reports_pointer',
        thClass: 'text-center',
        sortable: true
      },
      {
        key: 'previous.support',
        label: 'previous',
        tdClass: 'text-right reports_pointer',
        thClass: 'text-center',
        sortable: true
      }
    ],
    selected: [],
    query_filter: null
  }),
  computed: {
    ...mapState('reportSoftwareDashboardStore', ['users'])
  },
  methods: {
    ...mapMutations("reportSoftwareDashboardStore", ["setUsersSelected"]),
    rowClicked(e) {
      e._showDetails = !e._showDetails && e.children.length
    },
  },
  watch: {
    selected() {
      this.setUsersSelected(this.selected)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
