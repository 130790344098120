<template>
  <div class="reports_section_graphic" :class="isFullScreenMode?'reports_section_full_screen':''">
    <canvas ref="software_graphic_pending" id="canvas" width="400" height="400"></canvas>
  </div>
</template>

<script>
import * as Chart from "chart.js"
import 'chartjs-plugin-zoom'
import {mapGetters, mapState} from "vuex";
import fullscreen from "@/utils/fullscreen";

export default {
  name: "pending_graphic",
  mixins: [fullscreen],
  data: () => ({
    chart: null
  }),
  mounted() {
    this.pending_graphic_type = 'horizontalBar'
    this.print_graphic()
  },
  destroyed() {
    this.$store.state.reportSoftwarePendingStore.pending_graphic.type = 'horizontalBar'
  },
  computed: {
    ...mapState("reportSoftwarePendingStore", ["users_selected"]),
    ...mapGetters("reportSoftwarePendingStore", ["pending_graphic", "pending_graphic_type"]),
  },
  methods: {
    async print_graphic() {
      if (!!this.chart) {
        this.chart.destroy()
      }
      this.chart = new Chart(this.$refs.software_graphic_pending, {
        type: this.pending_graphic_type, // horizontalBar, pie
        data: {
          labels: this.pending_graphic.labels,
          datasets: this.pending_graphic.data
        },
        options: {
          spanGaps: true,
          responsive: true,
          animations: true,
          scales: {
            y: {
              beginAtZero: true
            },
            yAxes: [{
              stacked: this.pending_graphic_type === 'horizontalBar'
            }],
            xAxes: [
              {
                stacked: this.pending_graphic_type === 'horizontalBar',
                ticks: {
                  display: this.pending_graphic_type === 'horizontalBar',
                }
              }
            ]
          },
          subtitle: {display: false},
          title: {display: false},
          legend: {display: false},
          plugins: {
            zoom: {
              pan: {
                enabled: true,
                mode: 'y'
              },
              zoom: {
                wheel: {
                  enabled: true,
                  speed: 0.5
                },
                enabled: true,
                mode: 'y',
              }
            },
          },
        }
      });
    },
  },
  watch: {
    users_selected: function () {
      this.print_graphic()
    },
    pending_graphic: function () {
      this.print_graphic()
    }
  }
}
</script>
