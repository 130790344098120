var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reports_section_summary",class:_vm.isFullScreenMode?'reports_section_full_screen':''},[_c('div',{staticStyle:{"width":"100%"}},[_c('b-input',{attrs:{"placeholder":"Type to search"},model:{value:(_vm.query_filter),callback:function ($$v) {_vm.query_filter=$$v},expression:"query_filter"}})],1),_c('b-table',{attrs:{"bordered":"","small":"","selectable":"","select-mode":"single","fields":_vm.columns,"items":_vm.users,"filter":_vm.query_filter,"filter-included-fields":['username','children']},on:{"row-clicked":_vm.rowClicked},scopedSlots:_vm._u([{key:"thead-top",fn:function(data){return [_c('b-tr',[_c('b-th',{staticClass:"text-center",attrs:{"colspan":""}},[_c('span',[_vm._v("User")])]),_vm._l((_vm.$store.state.reportSoftwareAnalyticsStore.unit_control),function(item,index){return _c('b-th',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement : 'top',  arrow: true }),expression:"{ placement : 'top',  arrow: true }"}],key:index,staticClass:"text-center reports_pointer",class:_vm.$store.state.reportSoftwareAnalyticsStore.column_selected === item.key?'reports_columns_active':'',attrs:{"colspan":"2","content":'Graphic ' + item.value},on:{"click":function($event){return _vm.$store.commit('reportSoftwareAnalyticsStore/setColumSelected',item.key)}}},[_c('span',[_vm._v(_vm._s(item.value))])])})],2)]}},{key:"cell(username)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('div',{},[_c('b-form-checkbox',{attrs:{"value":item.id},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('div',{},[_c('span',{staticClass:"pb-0 mb-0 reports_dot",style:({background: item.colors.backgroundColor})}),_c('span',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement : 'top',  arrow: true }),expression:"{ placement : 'top',  arrow: true }"}],staticClass:"pb-0 mb-0 text-left",class:item.children.length?'font-weight-bold':'font-weight-bolder',attrs:{"content":item.username +'<br>'+ item.email + '<br>' + item.alpha3_code}},[_vm._v(" "+_vm._s((item.username) ? item.username.toUpperCase().substring(0, 15) : '')+" ")])])])]}},{key:"cell(current.DQ200)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.current.DQ200 > 0 ? 'text-success font-weight-bold': ''},[_vm._v(_vm._s(_vm._f("hideZeroValue")(item.current.DQ200)))])]}},{key:"cell(current.DQ250)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.current.DQ250 > 0 ? 'text-success font-weight-bold': ''},[_vm._v(_vm._s(_vm._f("hideZeroValue")(item.current.DQ250)))])]}},{key:"cell(current.DQ380)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.current.DQ380 > 0 ? 'text-success font-weight-bold': ''},[_vm._v(_vm._s(_vm._f("hideZeroValue")(item.current.DQ380)))])]}},{key:"cell(current.DQ381)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.current.DQ381 > 0 ? 'text-success font-weight-bold': ''},[_vm._v(_vm._s(_vm._f("hideZeroValue")(item.current.DQ381)))])]}},{key:"cell(current.DQ400)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.current.DQ400 > 0 ? 'text-success font-weight-bold': ''},[_vm._v(_vm._s(_vm._f("hideZeroValue")(item.current.DQ400)))])]}},{key:"cell(current.DQ500)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.current.DQ500 > 0 ? 'text-success font-weight-bold': ''},[_vm._v(_vm._s(_vm._f("hideZeroValue")(item.current.DQ500)))])]}},{key:"cell(current.DL382)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.current.DL382 > 0 ? 'text-success font-weight-bold': ''},[_vm._v(_vm._s(_vm._f("hideZeroValue")(item.current.DL382)))])]}},{key:"cell(current.DL501)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.current.DL501 > 0 ? 'text-success font-weight-bold': ''},[_vm._v(_vm._s(_vm._f("hideZeroValue")(item.current.DL501)))])]}},{key:"cell(current.Multitronic)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.current.Multitronic > 0 ? 'text-success font-weight-bold': ''},[_vm._v(_vm._s(_vm._f("hideZeroValue")(item.current.Multitronic)))])]}},{key:"cell(current.Automatic)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.current.Automatic > 0 ? 'text-success font-weight-bold': ''},[_vm._v(_vm._s(_vm._f("hideZeroValue")(item.current.Automatic)))])]}},{key:"cell(current.DL800)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.current.DL800 > 0 ? 'text-success font-weight-bold': ''},[_vm._v(_vm._s(_vm._f("hideZeroValue")(item.current.DL800)))])]}},{key:"cell(previous.DQ200)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.previous.DQ200 > 0 ? 'text-success font-weight-bold': ''},[_vm._v(_vm._s(_vm._f("hideZeroValue")(item.previous.DQ200)))])]}},{key:"cell(previous.DQ250)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.previous.DQ250 > 0 ? 'text-success font-weight-bold': ''},[_vm._v(_vm._s(_vm._f("hideZeroValue")(item.previous.DQ250)))])]}},{key:"cell(previous.DQ380)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.previous.DQ380 > 0 ? 'text-success font-weight-bold': ''},[_vm._v(_vm._s(_vm._f("hideZeroValue")(item.previous.DQ380)))])]}},{key:"cell(previous.DQ381)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.previous.DQ381 > 0 ? 'text-success font-weight-bold': ''},[_vm._v(_vm._s(_vm._f("hideZeroValue")(item.previous.DQ381)))])]}},{key:"cell(previous.DQ400)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.previous.DQ400 > 0 ? 'text-success font-weight-bold': ''},[_vm._v(_vm._s(_vm._f("hideZeroValue")(item.previous.DQ400)))])]}},{key:"cell(previous.DQ500)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.previous.DQ500 > 0 ? 'text-success font-weight-bold': ''},[_vm._v(_vm._s(_vm._f("hideZeroValue")(item.previous.DQ500)))])]}},{key:"cell(previous.DL382)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.previous.DL382 > 0 ? 'text-success font-weight-bold': ''},[_vm._v(_vm._s(_vm._f("hideZeroValue")(item.previous.DL382)))])]}},{key:"cell(previous.DL501)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.previous.DL501 > 0 ? 'text-success font-weight-bold': ''},[_vm._v(_vm._s(_vm._f("hideZeroValue")(item.previous.DL501)))])]}},{key:"cell(previous.Multitronic)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.previous.Multitronic > 0 ? 'text-success font-weight-bold': ''},[_vm._v(_vm._s(_vm._f("hideZeroValue")(item.previous.Multitronic)))])]}},{key:"cell(previous.Automatic)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.previous.Automatic > 0 ? 'text-success font-weight-bold': ''},[_vm._v(_vm._s(_vm._f("hideZeroValue")(item.previous.Automatic)))])]}},{key:"cell(previous.DL800)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.previous.DL800 > 0 ? 'text-success font-weight-bold': ''},[_vm._v(_vm._s(_vm._f("hideZeroValue")(item.previous.DL800)))])]}},{key:"row-details",fn:function(ref){
var item = ref.item;
return [_c('detail-users',{staticStyle:{"border":"1px solid black","padding-bottom":"0","margin-bottom":"0"},attrs:{"array_sub_users":item.children},scopedSlots:_vm._u([{key:"default",fn:function(item){return [_c('b-form-checkbox',{attrs:{"value":item.id},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('span',{staticClass:"pb-0 mb-0 font-weight-bolder"},[_vm._v(" "+_vm._s((item.username) ? item.username.toUpperCase().substring(0, 20) : '')+" ")])])]}}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }