<template>
  <b-modal size="lg" ref="modal-report-software" id="modal-report-software" @hidden="closeModal" title="Compare"
           no-close-on-backdrop no-close-on-esc hide-footer>
    <b-row class="text-center">
      <b-col>
        <h6 v-if="errorMessages.length" class="text-danger">Make sure previous and current date ranges are correct.</h6>
      </b-col>
    </b-row>
    <b-row v-if="['dashboard','tune'].includes(pageSelected)">
      <div class="w-100 text-center">
        <h6>Previous date range</h6>
      </div>
    </b-row>
    <b-row class="text-center justify-content-center">
      <div class="col-lg-6 col-sm-12">
        <label>From previous</label>
        <b-datepicker id="from_previous"
                      placeholder="YYYY-MM-DD"
                      format="yyyy-MM-dd"
                      v-model="params.from_previous"
                      locale="en"
                      typeable
                      calendar-width="350"></b-datepicker>
      </div>
      <div class="col-lg-6 col-sm-12" v-if="['dashboard','tune'].includes(pageSelected)">
        <label>To previous</label>
        <b-datepicker id="to_previous"
                      placeholder="YYYY-MM-DD"
                      format="yyyy-MM-dd"
                      v-model="params.to_previous"
                      locale="en"
                      typeable
                      calendar-width="350"
        ></b-datepicker>
      </div>
      <div class="w-100 text-center mt-4">
        <h6>Current date range</h6>
      </div>
      <div class="col-lg-6 col-sm-12" v-if="['dashboard','tune'].includes(pageSelected)">
        <label>From current</label>
        <b-datepicker id="from_current"
                      placeholder="YYYY-MM-DD"
                      format="yyyy-MM-dd"
                      v-model="params.from_current"
                      locale="en"
                      typeable
                      calendar-width="350"
        ></b-datepicker>
      </div>
      <div class="col-lg-6 col-sm-12">
        <label>To current</label>
        <b-datepicker id="to_current"
                      placeholder="YYYY-MM-DD"
                      format="yyyy-MM-dd"
                      v-model="params.to_current"
                      locale="en"
                      typeable
                      calendar-width="350"
        ></b-datepicker>
      </div>
    </b-row>
    <b-row class="text-center mt-4">
      <b-col>
        <b-button variant="primary" @click="loadData">Search</b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import * as moment from 'moment'

export default {
  name: "_modalDates",
  props: {
    pageSelected: null
  },
  data: () => ({
    params: {
      from_previous: null,
      to_previous: null,
      from_current: null,
      to_current: null,
    },
    showErrorDate: false,
    errorMessages: [],
    validationLessDates: {
      from_previous_to_previous: {status: true, text: "The from previous date must be less than the to previous date"},
      from_previous_from_current: {
        status: true,
        text: "The from previous date must be less than the from current date"
      },
      from_previous_to_current: {status: true, text: "The from previous date must be less than the to current date"},
      to_previous_from_current: {status: true, text: "The to previous date must be less than the from current date"},
      to_previous_to_current: {status: true, text: "The to previous date must be less than the to current date"},
      from_current_to_current: {status: true, text: "The from current date must be less than the to current date"},
    }
  }),
  methods: {
    loadData() {
      this.showErrorDate = true
      this.formatDates()
      if (this.checkIsLessThan()) {
        this.$emit('load_data', this.params)
        this.clearModal()
        this.$bvModal.hide('modal-report-software')
      } else {
        this.handleClosed()
      }
    },
    formatDates() {
      this.params.from_previous = moment(this.params.from_previous).format("YYYY-MM-DD")
      this.params.to_previous = moment(this.params.to_previous).format("YYYY-MM-DD")
      this.params.from_current = moment(this.params.from_current).format("YYYY-MM-DD")
      this.params.to_current = moment(this.params.to_current).format("YYYY-MM-DD")
    },
    checkDates() {
      return this.params.from_previous && this.params.to_previous && this.params.from_current && this.params.to_current &&
        this.params.from_previous !== '' && this.params.to_previous !== '' && this.params.from_current !== '' && this.params.to_current !== ''
    },
    checkIsLessThan() {
      if (['dashboard', 'tune'].includes(this.pageSelected)) {
        this.validationLessDates.from_previous_to_previous.status = moment(this.params.from_previous).isBefore(this.params.to_previous)
        this.validationLessDates.from_previous_from_current.status = moment(this.params.from_previous).isBefore(this.params.from_current)
        this.validationLessDates.from_previous_to_current.status = moment(this.params.from_previous).isBefore(this.params.to_current)
        this.validationLessDates.to_previous_from_current.status = moment(this.params.to_previous).isBefore(this.params.from_current)
        this.validationLessDates.to_previous_to_current.status = moment(this.params.to_previous).isBefore(this.params.to_current)
        this.validationLessDates.from_current_to_current.status = moment(this.params.from_current).isBefore(this.params.to_current)
        return this.validationLessDates.from_previous_to_previous.status && this.validationLessDates.from_previous_from_current.status && this.validationLessDates.from_previous_to_current.status
          && this.validationLessDates.to_previous_from_current.status && this.validationLessDates.to_previous_to_current.status && this.validationLessDates.from_current_to_current.status &&
          this.validationLessDates.from_current_to_current.status
      } else {
        this.params.to_previous = this.params.from_previous
        this.params.from_current = this.params.to_current
        return moment(this.params.from_previous).isBefore(this.params.to_current)
      }
    },
    handleClosed() {
      this.errorMessages = []
      Object.keys(this.validationLessDates).forEach(item => {
        if (!this.validationLessDates[item].status) {
          this.errorMessages.push(this.validationLessDates[item].text)
        }
      })
    },

    clearModal() {
      this.from_previous = null
      this.to_previous = null
      this.from_current = null
      this.to_current = null
      this.params = {
        from_previous: null,
        to_previous: null,
        from_current: null,
        to_current: null,
      }
      this.errorMessages = []
    },
    closeModal() {
      this.clearModal()
      this.$bvModal.hide('modal-report-software')
    }
  }
}
</script>

<style scoped>
.b-calendar-grid-body > .row .no-gutters {
  flex-wrap: nowrap;
}

.b-calendar-grid-body > .no-gutters > .col, .no-gutters > [class*="col-"] {
  width: 30px;
}

.table th, .table td {
  padding: 0.2rem;
}
</style>
