<template>
  <table class="table small">
    <tbody>
    <tr v-for="(item, index) in array_sub_users" :key="index">
      <td>
        <slot v-bind="item"></slot>
      </td>
      <td>
        <span class="font-weight-bolder" :class="item.movements.price < 0 ? 'text-danger': ''">{{
            item.movements.price|euro
          }}</span>
      </td>
      <td>
        <span class="font-weight-bolder"
              :class="item.orders.price < 0 ? 'text-danger': ''">{{ item.orders.price|euro }}</span>
      </td>
      <td>
        <span class="font-weight-bolder" :class="item.balance < 0 ? 'text-danger': ''">{{ item.balance|number }}</span>
      </td>
    </tr>
    </tbody>
  </table>
</template>
<script>

import {mapMutations} from "vuex";

export default {
  name: "_detailUserPending",
  props: {
    array_sub_users: [],
  },
  data: () => ({
    selected: [],
  }),
  methods: {
    ...mapMutations("reportSoftwarePendingStore", ["setUsersSelected"]),
  },
}
</script>

<style scoped lang="scss">
.sub_title_table {
  font-size: 0.6rem;
}

.table {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

</style>
