var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reports_section_summary",class:_vm.isFullScreenMode?'reports_section_full_screen':''},[_c('div',{staticStyle:{"width":"100%"}},[_c('b-input',{attrs:{"placeholder":"Type to search"},model:{value:(_vm.query_filter),callback:function ($$v) {_vm.query_filter=$$v},expression:"query_filter"}}),_c('b-table',{attrs:{"bordered":"","small":"","selectable":"","select-mode":"single","fields":_vm.columns,"items":_vm.pending_users_summary,"filter":_vm.query_filter,"filter-included-fields":['username','children']},on:{"row-clicked":_vm.rowClicked},scopedSlots:_vm._u([{key:"cell(username)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('div',{},[_c('b-form-checkbox',{attrs:{"value":item.id},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('div',{},[_c('span',{staticClass:"pb-0 mb-0 reports_dot",style:({background: item.colors.backgroundColor})}),_c('span',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement : 'top',  arrow: true }),expression:"{ placement : 'top',  arrow: true }"}],staticClass:"pb-0 mb-0 text-left",class:item.children.length?'font-weight-bold':'font-weight-bolder',attrs:{"content":item.username +'<br>'+ item.email + '<br>' + item.alpha3_code}},[_vm._v(" "+_vm._s((item.username) ? item.username.toUpperCase().substring(0, 15) : ''))])])])]}},{key:"cell(balance)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bolder",class:item.balance < 0 ? 'text-danger': ''},[_vm._v(_vm._s(_vm._f("euro")(item.balance)))])]}},{key:"cell(movements.price)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bolder",class:item.movements.price < 0 ? 'text-danger': ''},[_vm._v(_vm._s(_vm._f("euro")(item.movements.price)))])]}},{key:"cell(orders.price)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bolder",class:item.orders.price < 0 ? 'text-danger': ''},[_vm._v(_vm._s(_vm._f("euro")(item.orders.price)))])]}},{key:"row-details",fn:function(ref){
var item = ref.item;
return [_c('detail-user-pending',{staticClass:"child",attrs:{"array_sub_users":item.children},scopedSlots:_vm._u([{key:"default",fn:function(item){return [_c('b-form-checkbox',{attrs:{"value":item.id},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('span',{staticClass:"pb-0 mb-0 font-weight-bolder"},[_vm._v(" "+_vm._s((item.username) ? item.username.toUpperCase().substring(0, 20) : '')+" ")])])]}}],null,true)})]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }