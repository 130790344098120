<template>
  <div class="reports_section_summary" :class="isFullScreenMode?'reports_section_full_screen':''">
    <div style="width: 100%">
      <b-input v-model="query_filter" placeholder="Type to search"></b-input>
      <b-table bordered small selectable select-mode="single" :fields="columns" :items="pending_users_summary"
               @row-clicked="rowClicked" :filter="query_filter" :filter-included-fields="['username','children']">
        <template #cell(username)="{item}">
          <div class="d-flex">
            <div class="">
              <b-form-checkbox v-model="selected" :value="item.id"></b-form-checkbox>
            </div>
            <div class="">
              <span :style="{background: item.colors.backgroundColor}" class="pb-0 mb-0 reports_dot"></span>
              <span class="pb-0 mb-0 text-left" :class="item.children.length?'font-weight-bold':'font-weight-bolder'"
                    v-tippy="{ placement : 'top',  arrow: true }"
                    :content="item.username +'<br>'+ item.email + '<br>' + item.alpha3_code ">
              {{ (item.username) ? item.username.toUpperCase().substring(0, 15) : '' }}</span>
            </div>
          </div>
        </template>
        <template #cell(balance)="{item}">
        <span class="font-weight-bolder" :class="item.balance < 0 ? 'text-danger': ''">{{ item.balance|euro }}</span>
        </template>
        <template #cell(movements.price)="{item}">
        <span class="font-weight-bolder" :class="item.movements.price < 0 ? 'text-danger': ''">{{ item.movements.price|euro }}</span>
        </template>
        <template #cell(orders.price)="{item}">
        <span class="font-weight-bolder" :class="item.orders.price < 0 ? 'text-danger': ''">{{ item.orders.price|euro }}</span>
        </template>
        <template #row-details="{item}">
          <detail-user-pending :array_sub_users="item.children" class="child">
            <template #default="item">
              <b-form-checkbox v-model="selected" :value="item.id">
              <span class="pb-0 mb-0 font-weight-bolder">
                {{ (item.username) ? item.username.toUpperCase().substring(0, 20) : '' }}
              </span>
              </b-form-checkbox>
            </template>
          </detail-user-pending>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import fullscreen from "@/utils/fullscreen";
import {mapMutations, mapState} from "vuex";
import detailUserPending from "./_detailUserPending"

export default {
  name: "pending_summary",
  mixins: [fullscreen],
  components: {
    detailUserPending
  },
  data: () => ({
    columns: [
      {key: 'username', label: 'User',  sortable: true},
      {key: 'movements.price', label: 'Credits balance', tdClass: 'text-right reports_pointer', thClass: 'text-center',  sortable: true},
      {
        key: 'orders.price',
        label: 'Outstanding charges',
        tdClass: 'text-right reports_pointer',
        thClass: 'text-center',
        sortable: true
      },
      {key: 'balance', label: 'Expected balance', tdClass: 'text-right reports_pointer', thClass: 'text-center',  sortable: true}
    ],
    selected: [],
    query_filter: null
  }),
  computed: {
    ...mapState('reportSoftwarePendingStore', ['pending_users_summary'])
  },
  mounted() {
    this.$store.dispatch("reportSoftwarePendingStore/fetchSoftware")
  },
  methods: {
    ...mapMutations("reportSoftwarePendingStore", ["setUsersSelected"]),
    rowClicked(e) {
      e._showDetails = !e._showDetails && e.children.length
    },
  },
  watch: {
    selected() {
      this.setUsersSelected(this.selected)
    }
  }
}
</script>

<style scoped lang="scss">
.child {
  border: 1px solid black;
  padding-bottom: 0;
  margin-bottom: 0
}
</style>
