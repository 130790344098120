<template>
  <div class="reports_section_summary" :class="isFullScreenMode?'reports_section_full_screen':''">
    <div style="width: 100%">
      <b-input v-model="query_filter" placeholder="Type to search"></b-input>
    </div>
    <b-table bordered small selectable select-mode="single" :fields="columns" :items="users" @row-clicked="rowClicked"
             :filter="query_filter" :filter-included-fields="['username','children']"
    >
      <template #thead-top="data">
        <b-tr>
          <b-th colspan="" class="text-center"><span>User</span></b-th>
          <b-th v-for="(item, index) in $store.state.reportSoftwareAnalyticsStore.unit_control" :key=index colspan="2"
                class="text-center reports_pointer"
                v-tippy="{ placement : 'top',  arrow: true }"
                :content=" 'Graphic ' + item.value"
                :class="$store.state.reportSoftwareAnalyticsStore.column_selected === item.key?'reports_columns_active':''"
                @click="$store.commit('reportSoftwareAnalyticsStore/setColumSelected',item.key)">
            <span>{{ item.value }}</span>
          </b-th>
        </b-tr>
      </template>
      <template #cell(username)="{item}">
        <div class="d-flex">
          <div class="">
            <b-form-checkbox v-model="selected" :value="item.id"></b-form-checkbox>
          </div>
          <div class="">
            <span :style="{background: item.colors.backgroundColor}" class="pb-0 mb-0 reports_dot"></span>
            <span class="pb-0 mb-0 text-left" :class="item.children.length?'font-weight-bold':'font-weight-bolder'" v-tippy="{ placement : 'top',  arrow: true }" :content="item.username +'<br>'+ item.email + '<br>' + item.alpha3_code ">
              {{ (item.username) ? item.username.toUpperCase().substring(0, 15) : '' }}
            </span>
          </div>
        </div>
      </template>

      <template #cell(current.DQ200)="{item}">
        <span :class="item.current.DQ200 > 0 ? 'text-success font-weight-bold': ''">{{ item.current.DQ200|hideZeroValue }}</span>
      </template>
      <template #cell(current.DQ250)="{item}">
        <span :class="item.current.DQ250 > 0 ? 'text-success font-weight-bold': ''">{{ item.current.DQ250| hideZeroValue }}</span>
      </template>
      <template #cell(current.DQ380)="{item}">
        <span :class="item.current.DQ380 > 0 ? 'text-success font-weight-bold': ''">{{ item.current.DQ380| hideZeroValue }}</span>
      </template>
      <template #cell(current.DQ381)="{item}">
        <span :class="item.current.DQ381 > 0 ? 'text-success font-weight-bold': ''">{{ item.current.DQ381| hideZeroValue }}</span>
      </template>
      <template #cell(current.DQ400)="{item}">
        <span :class="item.current.DQ400 > 0 ? 'text-success font-weight-bold': ''">{{ item.current.DQ400| hideZeroValue }}</span>
      </template>
      <template #cell(current.DQ500)="{item}">
        <span :class="item.current.DQ500 > 0 ? 'text-success font-weight-bold': ''">{{ item.current.DQ500| hideZeroValue }}</span>
      </template>
      <template #cell(current.DL382)="{item}">
        <span :class="item.current.DL382 > 0 ? 'text-success font-weight-bold': ''">{{ item.current.DL382| hideZeroValue }}</span>
      </template>
      <template #cell(current.DL501)="{item}">
        <span :class="item.current.DL501 > 0 ? 'text-success font-weight-bold': ''">{{ item.current.DL501| hideZeroValue }}</span>
      </template>
      <template #cell(current.Multitronic)="{item}">
        <span :class="item.current.Multitronic > 0 ? 'text-success font-weight-bold': ''">{{ item.current.Multitronic| hideZeroValue }}</span>
      </template>
      <template #cell(current.Automatic)="{item}">
        <span :class="item.current.Automatic > 0 ? 'text-success font-weight-bold': ''">{{ item.current.Automatic| hideZeroValue }}</span>
      </template>
      <template #cell(current.DL800)="{item}">
        <span :class="item.current.DL800 > 0 ? 'text-success font-weight-bold': ''">{{ item.current.DL800| hideZeroValue }}</span>
      </template>

      <template #cell(previous.DQ200)="{item}">
        <span :class="item.previous.DQ200 > 0 ? 'text-success font-weight-bold': ''">{{ item.previous.DQ200| hideZeroValue }}</span>
      </template>
      <template #cell(previous.DQ250)="{item}">
        <span :class="item.previous.DQ250 > 0 ? 'text-success font-weight-bold': ''">{{ item.previous.DQ250| hideZeroValue }}</span>
      </template>
      <template #cell(previous.DQ380)="{item}">
        <span :class="item.previous.DQ380 > 0 ? 'text-success font-weight-bold': ''">{{ item.previous.DQ380| hideZeroValue }}</span>
      </template>
      <template #cell(previous.DQ381)="{item}">
        <span :class="item.previous.DQ381 > 0 ? 'text-success font-weight-bold': ''">{{ item.previous.DQ381| hideZeroValue }}</span>
      </template>
      <template #cell(previous.DQ400)="{item}">
        <span :class="item.previous.DQ400 > 0 ? 'text-success font-weight-bold': ''">{{ item.previous.DQ400| hideZeroValue }}</span>
      </template>
      <template #cell(previous.DQ500)="{item}">
        <span :class="item.previous.DQ500 > 0 ? 'text-success font-weight-bold': ''">{{ item.previous.DQ500| hideZeroValue }}</span>
      </template>
      <template #cell(previous.DL382)="{item}">
        <span :class="item.previous.DL382 > 0 ? 'text-success font-weight-bold': ''">{{ item.previous.DL382| hideZeroValue }}</span>
      </template>
      <template #cell(previous.DL501)="{item}">
        <span :class="item.previous.DL501 > 0 ? 'text-success font-weight-bold': ''">{{ item.previous.DL501| hideZeroValue }}</span>
      </template>
      <template #cell(previous.Multitronic)="{item}">
        <span :class="item.previous.Multitronic > 0 ? 'text-success font-weight-bold': ''">{{ item.previous.Multitronic| hideZeroValue }}</span>
      </template>
      <template #cell(previous.Automatic)="{item}">
        <span :class="item.previous.Automatic > 0 ? 'text-success font-weight-bold': ''">{{ item.previous.Automatic| hideZeroValue }}</span>
      </template>
      <template #cell(previous.DL800)="{item}">
        <span :class="item.previous.DL800 > 0 ? 'text-success font-weight-bold': ''">{{ item.previous.DL800| hideZeroValue }}</span>
      </template>

      <template #row-details="{item}">
        <detail-users :array_sub_users="item.children"
                      style="border: 1px solid black; padding-bottom: 0; margin-bottom: 0">
          <template #default="item">
            <b-form-checkbox v-model="selected" :value="item.id">
              <span
                class="pb-0 mb-0 font-weight-bolder">
                {{ (item.username) ? item.username.toUpperCase().substring(0, 20) : '' }}
              </span>
            </b-form-checkbox>
          </template>
        </detail-users>
      </template>
    </b-table>
  </div>
</template>
<script>
import {mapMutations, mapState} from "vuex";
import rowsBalances from "../_rowsVariation"
import detailUsers from "./_detailUserTuneAnalytics"
import fullscreen from "@/utils/fullscreen";

export default {
  name: "tune_analytics_summary",
  components: {
    rowsBalances,
    detailUsers
  },
  mixins: [fullscreen],
  data: () => ({
    columns: [
      {key: 'username', label: '', sortable: true},
      {
        key: 'current.DQ200',
        label: 'current',
        tdClass: 'text-right reports_pointer font-weight-bolder',
        thClass: 'text-center',
        sortable: true
      },
      {
        key: 'previous.DQ200',
        label: 'previous',
        tdClass: 'text-right reports_pointer font-weight-bolder',
        thClass: 'text-center',
        sortable: true
      },
      {
        key: 'current.DQ250',
        label: 'current',
        tdClass: 'text-right reports_pointer font-weight-bolder',
        thClass: 'text-center',
        sortable: true
      },
      {
        key: 'previous.DQ250',
        label: 'previous',
        tdClass: 'text-right reports_pointer font-weight-bolder',
        thClass: 'text-center',
        sortable: true
      },
      {
        key: 'current.DQ200',
        label: 'current',
        tdClass: 'text-right reports_pointer font-weight-bolder',
        thClass: 'text-center',
        sortable: true
      },
      {
        key: 'previous.DQ250',
        label: 'previous',
        tdClass: 'text-right reports_pointer font-weight-bolder',
        thClass: 'text-center',
        sortable: true
      },
      {
        key: 'current.DQ380',
        label: 'current',
        tdClass: 'text-right reports_pointer font-weight-bolder',
        thClass: 'text-center',
        sortable: true
      },
      {
        key: 'previous.DQ380',
        label: 'previous',
        tdClass: 'text-right reports_pointer font-weight-bolder',
        thClass: 'text-center',
        sortable: true
      },
      {
        key: 'current.DQ381',
        label: 'current',
        tdClass: 'text-right reports_pointer font-weight-bolder',
        thClass: 'text-center',
        sortable: true
      },
      {
        key: 'previous.DQ381',
        label: 'previous',
        tdClass: 'text-right reports_pointer font-weight-bolder',
        thClass: 'text-center',
        sortable: true
      },
      {
        key: 'current.DQ400',
        label: 'current',
        tdClass: 'text-right reports_pointer font-weight-bolder',
        thClass: 'text-center',
        sortable: true
      },
      {
        key: 'previous.DQ400',
        label: 'previous',
        tdClass: 'text-right reports_pointer font-weight-bolder',
        thClass: 'text-center',
        sortable: true
      },
      {
        key: 'current.DQ500',
        label: 'current',
        tdClass: 'text-right reports_pointer font-weight-bolder',
        thClass: 'text-center',
        sortable: true
      },
      {
        key: 'previous.DQ500',
        label: 'previous',
        tdClass: 'text-right reports_pointer font-weight-bolder',
        thClass: 'text-center',
        sortable: true
      },
      {
        key: 'current.DL382',
        label: 'current',
        tdClass: 'text-right reports_pointer font-weight-bolder',
        thClass: 'text-center',
        sortable: true
      },
      {
        key: 'previous.DL382',
        label: 'previous',
        tdClass: 'text-right reports_pointer font-weight-bolder',
        thClass: 'text-center',
        sortable: true
      },
      {
        key: 'current.DL501',
        label: 'current',
        tdClass: 'text-right reports_pointer font-weight-bolder',
        thClass: 'text-center',
        sortable: true
      },
      {
        key: 'previous.DL501',
        label: 'previous',
        tdClass: 'text-right reports_pointer font-weight-bolder',
        thClass: 'text-center',
        sortable: true
      },
      {
        key: 'current.Multitronic',
        label: 'current',
        tdClass: 'text-right reports_pointer font-weight-bolder',
        thClass: 'text-center',
        sortable: true
      },
      {
        key: 'previous.Multitronic',
        label: 'previous',
        tdClass: 'text-right reports_pointer font-weight-bolder',
        thClass: 'text-center',
        sortable: true
      },
      {
        key: 'current.Automatic',
        label: 'current',
        tdClass: 'text-right reports_pointer font-weight-bolder',
        thClass: 'text-center',
        sortable: true
      },
      {
        key: 'previous.Automatic',
        label: 'previous',
        tdClass: 'text-right reports_pointer font-weight-bolder',
        thClass: 'text-center',
        sortable: true
      },
      {
        key: 'current.DL800',
        label: 'current',
        tdClass: 'text-right reports_pointer font-weight-bolder',
        thClass: 'text-center',
        sortable: true
      },
      {
        key: 'previous.DL800',
        label: 'previous',
        tdClass: 'text-right reports_pointer font-weight-bolder',
        thClass: 'text-center',
        sortable: true
      },
    ],
    selected: [],
    query_filter: null
  }),
  computed: {
    ...mapState('reportSoftwareAnalyticsStore', ['users'])
  },
  methods: {
    ...mapMutations("reportSoftwareAnalyticsStore", ["setUsersSelected"]),
    rowClicked(e) {
      e._showDetails = !e._showDetails && e.children.length
    },
  },
  watch: {
    selected() {
      this.setUsersSelected(this.selected)
    }
  }
}
</script>

<style scoped lang="scss">

</style>

