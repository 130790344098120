<template>
  <table class="table small">
    <tbody>
    <tr v-for="(item, index) in array_sub_users" :key="index">
      <td>
        <slot v-bind="item"></slot>
      </td>
      <td>
        <span class="font-weight-bolder">{{ item.current.DQ200|number }}</span>
      </td>
      <td>
        <span class="font-weight-bolder">{{ item.previous.DQ200|number }}</span>
      </td>
      <td>
        <span class="font-weight-bolder">{{ item.current.DQ250|number }}</span>
      </td>
      <td>
        <span class="font-weight-bolder">{{ item.previous.DQ250|number }}</span>
      </td>
      <td>
        <span class="font-weight-bolder">{{ item.current.DQ380|number }}</span>
      </td>
      <td>
        <span class="font-weight-bolder">{{ item.previous.DQ380|number }}</span>
      </td>
      <td>
        <span class="font-weight-bolder">{{ item.current.DQ381|number }}</span>
      </td>
      <td>
        <span class="font-weight-bolder">{{ item.previous.DQ381|number }}</span>
      </td>
      <td>
        <span class="font-weight-bolder">{{ item.current.DQ400|number }}</span>
      </td>
      <td>
        <span class="font-weight-bolder">{{ item.previous.DQ400|number }}</span>
      </td>
      <td>
        <span class="font-weight-bolder">{{ item.current.DQ500|number }}</span>
      </td>
      <td>
        <span class="font-weight-bolder">{{ item.previous.DQ500|number }}</span>
      </td>
      <td>
        <span class="font-weight-bolder">{{ item.current.DL382|number }}</span>
      </td>
      <td>
        <span class="font-weight-bolder">{{ item.previous.DL382|number }}</span>
      </td>
      <td>
        <span class="font-weight-bolder">{{ item.current.DL501|number }}</span>
      </td>
      <td>
        <span class="font-weight-bolder">{{ item.previous.DL501|number }}</span>
      </td>
      <td>
        <span class="font-weight-bolder">{{ item.current.Multitronic|number }}</span>
      </td>
      <td>
        <span class="font-weight-bolder">{{ item.previous.Multitronic|number }}</span>
      </td>
      <td>
        <span class="font-weight-bolder">{{ item.current.Automatic|number }}</span>
      </td>
      <td>
        <span class="font-weight-bolder">{{ item.previous.Automatic|number }}</span>
      </td>
      <td>
        <span class="font-weight-bolder">{{ item.current.DL800|number }}</span>
      </td>
      <td>
        <span class="font-weight-bolder">{{ item.previous.DL800|number }}</span>
      </td>
    </tr>
    </tbody>
  </table>
</template>
<script>

import {mapMutations} from "vuex";
import {reports_array_dsg} from "@/constants";

export default {
  name: "_detailUserPending",
  props: {
    array_sub_users: [],
  },
  data: () => ({
    selected: [],
    reports_array_dsg: reports_array_dsg()
  }),
  methods: {
    ...mapMutations("reportSoftwareAnalyticsStore", ["setUsersSelected"]),
  },
}
</script>

<style scoped lang="scss">
.sub_title_table {
  font-size: 0.6rem;
}

.table {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

</style>
