<template>
  <div>
    <b-overlay :show="showOverlay" rounded="lg">
      <div class="w-100 justify-content-center" v-if="$store.state.showReportsAlert">
        <b-alert show dismissible variant="dark" class="font-weight-bold text-white"> The data shown in the Pie Chart:
          The outer circle is the current data.
          The inner circle is the previous data</b-alert>
      </div>
      <!------------------------------------------------ START BTN ACTIONS -------------------------------------->
      <b-row>
        <div class="col-lg-9 col-md-12 col-sm-12 justify-content-around d-flex pr-0 pl-0">
          <span>From previous: {{ $store.state.reportSoftwareDashboardStore.params.from_previous }}</span>
          <span>To previous: {{ $store.state.reportSoftwareDashboardStore.params.to_previous }}</span>
          <span>From current: {{ $store.state.reportSoftwareDashboardStore.params.from_current }}</span>
          <span>To current: {{ $store.state.reportSoftwareDashboardStore.params.to_current }}</span>
        </div>
        <div class="col-lg-3 col-md-12 col-sm-12 pl-0 justify-content-end d-flex">
          <b-button @click="enterToFullscreen('section_graphic')" variant="outline-primary" size="sm"
                    v-tippy="{ placement : 'top',  arrow: true }"
                    content="Full screen view graphic">
            <i class="fa fa-expand"> Graphic</i>
          </b-button>
          <b-button @click="enterToFullscreen('section_summary')" variant="outline-primary" size="sm"
                    v-tippy="{ placement : 'top',  arrow: true }"
                    content="Full screen view table">
            <i class="fa fa-expand"> Table</i>
          </b-button>
          <b-button @click="enterToFullscreen('reports')" variant="outline-primary" size="sm"
                    v-tippy="{ placement : 'top',  arrow: true }"
                    content="Full screen view window">
            <i class="fa fa-arrows-alt"> Window</i>
          </b-button>
          <b-button @click="exportSoftware" variant="outline-primary" size="sm"
                    v-tippy="{ placement : 'top',  arrow: true }"
                    content="Download excel">
            <i class="fa fa-download" aria-hidden="true"></i>
          </b-button>
        </div>
      </b-row>
      <!------------------------------------------------ END BTN ACTIONS -------------------------------------->
      <!------------------------------------------------ START SECTIONS -------------------------------------->
      <b-row v-if="params.page === 'dashboard'">
        <div class="col-lg-6 col-md-12 col-sm-12 custom_div" id="section_graphic">
          <dashboard-graphic/>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 custom_div" id="section_summary">
          <dashboard-summary/>
        </div>
      </b-row>
      <b-row v-if="params.page === 'pending'">
        <div class="col-lg-6 col-md-12 col-sm-12 custom_div" id="section_graphic">
          <pending-graphic/>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 custom_div" id="section_summary">
          <pending-summary/>
        </div>
      </b-row>
      <b-row v-if="params.page === 'tune'">
        <div class="col-lg-6 col-md-12 col-sm-12 custom_div" id="section_graphic">
          <tune-analytics-graphic/>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 custom_div" id="section_summary">
          <tune-analytics-summary/>
        </div>
      </b-row>
      <!------------------------------------------------ END SECTIONS -------------------------------------->
      <!------------------------------------------------ START FILTERS -------------------------------------->
      <b-row class="mt-2 justify-content-center">
        <div v-if="['dashboard','tune'].includes(params.page)" class="col-lg-2 col-md-4 col-sm-12">
          <select class="form-control" v-model="params.time" @change="resetTimeDates">
            <option :value="item.key" v-for="(item, index) in times" :key="index">{{ item.value }}</option>
          </select>
        </div>
        <div v-if="['dashboard','tune','pending'].includes(params.page)" class="col-lg-2 col-md-4 col-sm-12">
          <select class="form-control" v-model="params.graphic" @change="setTypeGraphic">
            <option value="horizontalBar">Horizontal Bar</option>
            <option value="pie">Pie</option>
          </select>
        </div>
        <div v-if="['dashboard','tune'].includes(params.page)" class="col-lg-2 col-md-4 col-sm-12">
          <b-button variant="primary" class="btn-block" @click="showModalDates">Select dates</b-button>
        </div>
        <div v-if="['dashboard','tune','pending'].includes(params.page)" class="col-lg-2 col-md-4 col-sm-12">
          <select class="form-control" v-model="params.showDistributorBalance"
                  @change="groupBalances($event.target.value)">
            <option :value="true">Show distributor balance</option>
            <option :value="false">Show dealers balance</option>
          </select>
        </div>
        <div v-if="['dashboard','tune','pending'].includes(params.page)" class="col-lg-2 col-md-4 col-sm-12">
          <select class="form-control" v-model="params.page" @change="switchPage">
            <option value="dashboard">Default dashboard</option>
            <option value="pending">Pending orders</option>
            <option value="tune">Tune analytics</option>
          </select>
        </div>
      </b-row>
      <b-row class="mt-2 justify-content-center">
        <div v-if="['dashboard','tune'].includes(params.page)" class="col-lg-2 col-md-4 col-sm-12">
          <select class="form-control" v-model="params.order_status" @change="loadDataPageSelected">
            <option :value="null">Select Status</option>
            <option :value="item.value" v-for="(item, index) in orderStatus" :key="index">{{ item.text }}</option>
          </select>
        </div>
        <div v-if="['dashboard','tune'].includes(params.page)" class="col-lg-2 col-md-4 col-sm-12">
          <select class="form-control" v-model="params.order_type" @change="loadStages">
            <option value="all">ECU - DSG - COMBOS</option>
            <option value="ECU">ECU</option>
            <option value="DSG">DSG</option>
            <option value="ECU + DSG">COMBO</option>
          </select>
        </div>
        <div v-if="['dashboard','tune'].includes(params.page)" class="col-lg-2 col-md-4 col-sm-12">
          <select class="form-control" v-model="params.order_stage" @change="loadDataPageSelected">
            <option :value="null">Select Stage</option>
            <option :value="item.key" v-for="(item, index) in $store.state.reportSoftwareDashboardStore.stages"
                    :key="index">
              {{ item.value }}
            </option>
          </select>
        </div>
        <div v-if="['dashboard','tune'].includes(params.page)" class="col-lg-2 col-md-4 col-sm-12">
          <select class="form-control" v-model="params.unit_control" @change="switchPage">
            <option :value="null">Control unit type</option>
            <option v-for="(item, index) in $store.state.reportSoftwareDashboardStore.unit_control" :key="index"
                    :value="item.key">{{ item.value }}
            </option>
          </select>
        </div>
      </b-row>
      <!------------------------------------------------ END FILTERS -------------------------------------->
    </b-overlay>
    <modal-dates :page-selected="params.page" @load_data="loadModalDates"/>
  </div>
</template>

<script>

import {get} from "@/services/api";

const times = [
  {key: 'month', value: 'Last month'},
  {key: 'trimester', value: 'Last trimester'},
  {key: 'semester', value: 'Last semester'},
  {key: 'year', value: 'Last year'},
]
import {ORDER_STATUS} from "@/constants";
import DashboardSummary from "./dashboard_summary"
import DashboardGraphic from "./dashboard_graphic"
import PendingSummary from "./pending_summary"
import PendingGraphic from "./pending_graphic"
import TuneAnalyticsSummary from "./tune_analytics_summary"
import TuneAnalyticsGraphic from "./tune_analytics_graphic"
import modalDates from "./_modalDates";
import {mapMutations} from "vuex";
import fullscreen from "@/utils/fullscreen";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: "ReportsSoftware",
  components: {
    DashboardSummary,
    DashboardGraphic,
    modalDates,
    PendingSummary,
    PendingGraphic,
    TuneAnalyticsSummary,
    TuneAnalyticsGraphic
  },
  mixins: [fullscreen, show_alert_mixin],
  data: () => ({
    params: {
      time: 'month',
      from_previous: null,
      to_previous: null,
      from_current: null,
      to_current: null,
      compare: 'week',
      page: 'dashboard',
      graphic: 'horizontalBar',
      order_status: null,
      order_type: 'all',
      order_stage: null,
      unit_control: null,
      showDistributorBalance: true
    },
    showModal: false,
    orderStatus: ORDER_STATUS(),
    showOverlay: false,
    times: times,
  }),
  mounted() {
    this.showOverlay = true
    this.loadInitData().then(() => this.showOverlay = false)
  },
  methods: {
    ...mapMutations(['loaderManager']),
    ...mapMutations("reportSoftwareDashboardStore", ["setUsersSelected"]),
    async loadInitData() {
      this.$store.commit('reportSoftwareDashboardStore/setParams', this.params)
      this.setUsersSelected([])
      await this.$store.dispatch('reportSoftwareDashboardStore/fetchSoftwareOrders')
    },
    setTypeGraphic() {
      if(this.params.graphic === "pie"){
        this.$store.state.showReportsAlert = true
        setTimeout(()=>{
          this.$store.state.showReportsAlert = false
        },10000)
      }
      if (this.params.page === 'dashboard') {
        this.$store.commit('reportSoftwareDashboardStore/setGraphicType', this.params.graphic)
      } else if (this.params.page === 'pending') {
        this.$store.commit('reportSoftwarePendingStore/setGraphicType', this.params.graphic)
      } else if (this.params.page === 'tune') {
        this.$store.commit('reportSoftwareAnalyticsStore/setGraphicType', this.params.graphic)
      }

    },
    switchPage() {
      this.$store.state.reportSoftwareDashboardStore.users_selected = []
      this.$store.state.reportSoftwareAnalyticsStore.users_selected = []
      this.$store.commit("reportSoftwareDashboardStore/setShowDistributorBalance", true)
      this.$store.commit("reportSoftwarePendingStore/setShowDistributorBalance", true)
      this.$store.commit("reportSoftwareAnalyticsStore/setShowDistributorBalance", true)
      this.showDistributorBalance = 'true'
      this.resetTimeDates()
    },
    loadModalDates(params) {
      this.params.from_previous = params.from_previous
      this.params.to_previous = params.to_previous
      this.params.from_current = params.from_current
      this.params.to_current = params.to_current
      this.loadDataPageSelected()
    },
    resetTimeDates() {
      this.params.from_previous = null
      this.params.to_previous = null
      this.params.from_current = null
      this.params.to_current = null
      this.loadDataPageSelected()
    },
    loadDataPageSelected() {
      this.showOverlay = true
      if (this.params.page === 'dashboard') {
        this.$store.commit("reportSoftwareDashboardStore/setParams", this.params)
        this.$store.dispatch("reportSoftwareDashboardStore/fetchSoftwareOrders").then(() => this.showOverlay = false)
      } else if (this.params.page === 'pending') {
        this.$store.commit("reportSoftwarePendingStore/setParams", this.params)
        this.$store.dispatch("reportSoftwarePendingStore/fetchSoftware").then(() => this.showOverlay = false)
      } else if (this.params.page === 'tune') {
        this.$store.commit("reportSoftwareAnalyticsStore/setParams", this.params)
        this.$store.dispatch("reportSoftwareAnalyticsStore/fetchSoftwareAnalytics").then(() => this.showOverlay = false)
      }
    },
    loadStages() {
      this.$store.commit("reportSoftwareDashboardStore/setParams", this.params)
      this.$store.dispatch('reportSoftwareDashboardStore/fetchCombos')
      this.loadDataPageSelected()
    },
    showModalDates() {
      this.$root.$emit('bv::show::modal', 'modal-report-software', '#btnShow')
    },
    exportSoftware() {
      this.loaderManager(true)
      let url = `software-${this.params.page}-export-excel`
      get(`reports/${url}?order_type=${encodeURIComponent(this.params.order_type)}&stage=${encodeURIComponent(this.params.order_stage)}&time=${this.params.time}&status=${this.params.order_status}&from_previous=${this.params.from_previous}&to_previous=${this.params.to_previous}&from_current=${this.params.from_current}&to_current=${this.params.to_current}`, null, true, 'arraybuffer').then(({data}) => {
        const fileURL = window.URL.createObjectURL(new Blob([data]));
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', `Software orders ${this.params.page}.xlsx`);
        document.body.appendChild(fileLink);
        fileLink.click();
        this.loaderManager(false);
      }).catch(() => {
        this.loaderManager(false);
      });
    },
    groupBalances(value) {
      if (this.params.page === 'dashboard') {
        this.$store.commit("reportSoftwareDashboardStore/setShowDistributorBalance", value === 'true')
        this.$store.dispatch("reportSoftwareDashboardStore/groupDealersBalanceDistributor")
      } else if (this.params.page === 'pending') {
        this.$store.commit("reportSoftwarePendingStore/setShowDistributorBalance", value === 'true')
        this.$store.dispatch("reportSoftwarePendingStore/groupDealersBalanceDistributor")
      }else {
        this.$store.commit("reportSoftwareAnalyticsStore/setShowDistributorBalance", value === 'true')
        this.$store.dispatch("reportSoftwareAnalyticsStore/groupDealersBalanceDistributor")
      }
    }
  }
}
</script>

<style scoped lang="scss">
.chart_full_screen {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

@media only screen and (max-width: 600px) {
  .custom_div {
    margin: 1rem 0;
  }
}
</style>
